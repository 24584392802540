import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes:{
            light:{
				primary:'#fe7b00',
                color1: '#1a237e',
                color2: '#e64a19',
                color3: '#ffffff'
            }
        }
    },
});
