import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { library} from '@fortawesome/fontawesome-svg-core'
import axios from 'axios'
import VueAxios from 'vue-axios'
import CKEditor from '@ckeditor/ckeditor5-vue2'
//import VueAxios from 'vue-axios'
import { 
  faEyeSlash,faEnvelopeOpen,faUserCircle, faKey, faLock,faServer, faImage,faSpinner,faBell,faInfoCircle,faLockOpen,faStar,faDashboard,
  faClose, faCheck,faUserGroup, faUserPlus, faPause, faHotel,faAngleLeft,faAngleRight,faAd,faBook,faPlus,faEdit,
  faPlay,faTasks ,faSearch,faBuilding,faInstitution,faArrowCircleRight,faHome,faGear,faCreditCard,faEllipsisV,
  faTrashCan,faBars,faAngleDoubleRight,faQuestionCircle,faCheckSquare,faTimesCircle,faChevronCircleLeft,faPhone,faRefresh,faBank,faTruck,faAddressCard
} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faEyeSlash,faUserCircle,faKey, faLock, faClose,faImage, faServer ,faSpinner, faBell, faArrowCircleRight,faLockOpen,faStar,faEllipsisV,
  faCheck, faUserGroup, faUserPlus, faPause, faPlay, faHotel, faAngleLeft,faEdit,
  faAngleRight, faGear, faAd,faDashboard,faAngleDoubleRight,faChevronCircleLeft,faTruck,
  faEnvelopeOpen, faTasks,faQuestionCircle, faSearch, faBuilding, faInstitution,faPhone,faRefresh,
  faInfoCircle, faHome, faCreditCard, faBook, faCheckSquare,faTimesCircle,faPlus, faTrashCan,faBars,faBank,faAddressCard)

Vue.component('fai',FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(VueAxios , axios)
Vue.use(CKEditor)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
