import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [

  {
    path:'/',
    name:"Login",
    meta:{
      title:'Admin Login'
    },
   component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },

  {
    path:'/userData/:id',
    meta:{
      title:'User Data'
    },
    name:'UserData',
    component:() => import('../views/UserData.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path:'/shipping',
    meta:{
      title:'Shipping Company'
    },
    name:'ShippingCompany',
    component:() => import('../views/ShippingCompany.vue'),
    beforeEnter:async(to,from, next)=>{
    let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path:'/orderInfo/:order_id',
    meta:{
      title:'Order Info'
    },
    name:'Orderinfo',
    component:() => import('../views/OrderInfo.vue'),
    beforeEnter:async(to,from, next)=>{
      let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },


  {
    path:'/finance',
    meta:{
      title:'Finance'
    },
    name:'UserData',
    component:() => import('../views/Finance.vue'),
    beforeEnter:async(to,from, next)=>{
      let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path:'/home',
    name:'Home',
    meta:{
      title:'Home'
    },
    component:() => import('../views/Home.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path:'/notifications/:page',
    name:'Notification',
    meta:{
      title:'Notification'
    },
    component:() => import('../views/Notifications.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/settings',
    name: 'Setting',
    meta:{
      title:'Setting'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/products',
    name: 'Product',
    meta:{
      title:'products'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue'),
    beforeEnter:async(to,from, next)=>{
      let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/transactions',
    name: 'Transaction',
    meta:{
      title:'Transaction'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Transactions.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },
  {
    path: '/users',
    name: 'Users',
    meta:{
      title:'users'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
    beforeEnter:async(to,from, next)=>{
      let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/productInformation/:slug',
    name: 'productInformation',
    meta:{
      title:'Product Information'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductInformation.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

   {
    path: '/admin',
    name: 'Admin',
    meta:{
      title:'Admin'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    beforeEnter:async(to,from, next)=>{
      let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

   {
    path: '/pending_transaction',
    name: 'PendingTransaction',
    meta:{
      title:'PendingTransaction'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PendingTransaction.vue'),
    beforeEnter:async(to,from, next)=>{
      let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/promotions',
    name: 'Promotion',
    meta:{
      title:'Promotion'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Promotions.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/faqs',
    name: 'faq',
    meta:{
      title:'Faq'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Faqs.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/orders',
    name: 'Order',
    meta:{
      title:'Order'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Orders.vue'),
    beforeEnter:async(to,from, next)=>{
     let tk =  await store.dispatch('authenticate');
      if(!tk){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  }

]

const router = new VueRouter({
  mode:'history',
  base:process.env.BASE_URL,
  routes
})

export default router
