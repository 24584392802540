<template>
	<v-app>
		<v-main>
			<router-view/>
		</v-main>
		<Feedback 
		:show="this.$store.getters.get_show" 
		:progress="this.$store.getters.get_progress" 
		:success="this.$store.getters.get_success" 
		:error="this.$store.getters.get_error"
		/>
	</v-app>
</template>

<script>
	import Feedback from '@/components/Feedback.vue'
	export default {
		name: 'App',

		data: () => ({
			
		}),
		components:{
			Feedback
		},
		created(){
			console.log(window.localStorage.getItem('token'))
	// intercepting requests
	this.axios.interceptors.request.use(function (config) {
		return config;
	}, function (error) {

		this.$router.push('/')
		return Promise.reject(error);
	});
	//
}
};
</script>
