import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //api: `http://${window.location.hostname}:3333/api/`,
    api:'https://proudlyanambra.com/api/',
    apiKey:"N23mq_KDmM7LgTv3ebYn4RfKD7OztKMt",
    token:undefined,
    email:undefined,
    mode:false,//false IS LIGHT MODE, true IS DARK MODE
    notice:'0',
    trans:'0',
    menuoptions:[
      {name:"Home" , icon:"home"},
      {name:"Admin" , icon:"user-circle"},
      {name:"Users" , icon:"user-group"},
      {name:"Products" , icon:"tasks"},
      {name:"Settings" , icon:"gear"},
      {name:"Transactions" , icon:"credit-card"},
      {name:"Promotions" , icon:"star"},
      {name:"Orders" , icon:"book"},
      {name:"Finance" , icon:'bank'},
      {name:"Shipping" , icon:'truck'},
      {name:"Faqs" , icon:'question-circle'},
      {name:"Pending_Transaction" , icon:'bank'}
    ],
    logo_word:require('../assets/100pxl.png'),
    logo:require('../assets/100pxl.png'),

    message:'An error occured when saving user',
    success:false,
    error:false,
    progress:false,
    show:false
  },
  mutations: {
    set_token(state,value){
      state.token = value;
    },
    set_mode(state,value){
      state.mode = value;
    },
    set_notice(state,value){
      state.notice = value;
    },
    set_trans(state , value){
      state.trans = value
    },
    set_show(state,value){
      state.show = value
    },
    set_message(state,value){
      state.message = value
    },
    set_progress(state,value){
      state.progress = value
    },
    set_success(state,value){
      state.success = value
    },
    set_error(state,value){
      state.error = value
    }
  },
  getters: {
    get_api:(state)=>state.api,
    get_api_key:(state)=>state.apiKey,
    get_token:(state)=>state.token,
    get_mode:(state)=>state.mode,
    get_notice:(state)=>state.notice,
    get_trans:(state)=>state.trans,
    get_menu:(state)=>state.menuoptions,
    get_logo_word:(state)=>state.logo_word,
    get_logo:(state)=>state.logo,
    get_show:(state)=>state.show,
    get_message:(state)=>state.message,
    get_progress:(state)=>state.progress,
    get_success:(state)=>state.success,
    get_error:(state)=>state.error
  },
  modules: {
  },
  actions: {
    openProgress:async function(context){
      context.commit('set_error',false)
      context.commit('set_success',false)
      context.commit('set_progress',true)
      context.commit('set_message',undefined)
      context.commit('set_show',true)
    },
    closeProgress:async function(context){
       context.commit('set_error',false)
        context.commit('set_success',false)
        context.commit('set_progress',false)
        context.commit('set_message',undefined)
        context.commit('set_show',false)
    },
    totalNotice:async function(context){
      axios({
        url:context.getters.get_api+"fetchTotalNewNotificationsAdmin",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${context.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          context.commit('set_notice',data.message[0].total)
        }
      }).catch((error)=>{
        //console.log(context)
        return error
      })
     },
     logout:async function(context){
      context.dispatch('openProgress');
      axios({
        url:context.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${context.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          context.commit("set_token" , null);
          window.localStorage.setItem('token', null)
          context.dispatch('closeProgress');
          router.push("/")
        }
      }).catch((error)=>{
        context.commit('set_progress',false);
        context.commit('set_error',true)
        context.commit('set_message','Network Error');
        return error
      })
     },
      totalTransaction:async function(context){
      axios({
        url:context.getters.get_api+"totalDailyTransations",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${context.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        //console.log(data.message[0][0].total)
        if(!data.error){
          if(data.message[0][0].total){
            context.commit('set_trans',data.message[0][0].total)
          }
          //context.commit('set_trans',data.message[0].total)
        }
      }).catch((error)=>{
        //console.log(context)
        return error
      })
     },

     authenticate:async function(context){
       console.log('checking token')
       let to = await localStorage.getItem('token')
        console.log(to)
       console.log(to.length)
        console.log( typeof to)
       if(to.length>15){

         context.commit('set_token',window.localStorage.getItem('token'));
         return true;
       }else{
         context.commit('set_token',undefined);
         return false;
       }
     }
  }
})
